import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, Stack, Alert, IconButton, InputAdornment, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PATH_AUTH } from "../../../routes/paths";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { Validation } from "src/utils/validations";
import { useSnackbar } from "notistack";

export default function LoginForm() {
  const { login, authenticateDiscord, verifyAuthentication } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    emailOrUserName: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(Validation.LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get("error");

    if (error) {
      window.history.replaceState({}, document.title, window.location.pathname);
      return enqueueSnackbar(error, {
        autoHideDuration: 8000,
        variant: "error",
      });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) {
      window.history.replaceState({}, document.title, window.location.pathname);
      verifyAuthentication(token);
    }
  }, [verifyAuthentication]);

  const onSubmit = async (data) => {
    try {
      await login(data.emailOrUserName, data.password);
    } catch (error) {
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError("afterSubmit", { message: error });
      }
    }
  };

  const onAuthenauthenticateDiscord = async () => {
    try {
      setLoading(true);
      await authenticateDiscord();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField
          name="emailOrUserName"
          label="Username or email address"
        />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
        <Link
          component={RouterLink}
          variant="subtitle2"
          to={PATH_AUTH.resetPassword}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ my: 2 }}
      >
        <Typography sx={{ color: "text.secondary" }}>
          Or connect with
        </Typography>
      </Stack>

      <Stack spacing={3} >
        <LoadingButton
          startIcon={<Iconify icon="ic:baseline-discord" width={24} />}
          sx={{ m: 3 }}
          fullWidth
          size="large"
          variant="contained"
          onClick={onAuthenauthenticateDiscord}
          loading={loading}
        >
          Discord
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
